// Gatsby Requirements
import React, { useState } from "react"

// Style
import * as CSS from './NewsletterForm.module.scss'


const ContactForm = () => {

    const [state, setState] = React.useState({})

    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value })
    }

    function encode(data) {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
    }
  
    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => setFormSubmitted(true))
        .catch((error) => alert(error))
    }

    return (
        <form className={CSS.form} name="Newsletter Signup" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="Newsletter Signup" />
            <p hidden>
                <label>
                    Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
            </p>
            <div>
                <label>
                    <input onFocus={handleChange} onChange={handleChange} type="email" id="email" name="email" required />
                    <span className={state.email !== undefined ? CSS.animate : ''}>Enter Your Email</span>
                </label>
                <button disabled={formSubmitted} className="button" type="submit" name="submit">Submit</button>
            </div>
            <p style={{display: formSubmitted ? 'block' : 'none'}} className="newsletter-form-submitted">Submitted. Thank you!</p>
        </form>
    )
}

export default ContactForm