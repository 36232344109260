// Gatsby requirements
import React from "react"

// Components
import RichText from "utils/RichTextRenderer"
import NewsletterForm from "components/NewsletterForm/NewsletterForm"
import SocialMedia from "components/SocialMedia/SocialMedia"

// Styles
import * as CSS from './Footer.module.scss'


const Footer = ({ footerData, previewData, slug, noFooterMargin }) =>  {

    const content = previewData?.content || JSON.parse(footerData.content);
    
    return(
        <footer data-color="var(--dark-blue)" className={`${CSS.footer} ${noFooterMargin ? CSS.noFooterMargin : ''}`}>

            <div className="wrapper">
                <div className={CSS.topLeft}>
                    {content?.leftTitle &&
                        <h2>{content.leftTitle}</h2>
                    }
                    {content?.leftColumn1 && 
                        <div>
                            <RichText data={content.leftColumn1} />
                        </div>
                    }
                    {content?.leftColumn2 && 
                        <div>
                            <RichText data={content.leftColumn2} />
                        </div>
                    }
                </div>
                <div className={CSS.topRight}>
                    <RichText data={content.rightRichText} />
                    <NewsletterForm/>
                    <SocialMedia/>
                </div>
            </div>

            <div className={`${CSS.bottom} wrapper`}>
                <div>
                    <RichText data={content.bottomLeftLegals} />
                </div>
                <div className={CSS.bottomRight}>
                    <RichText data={content.bottomRightLegals} />
                    {slug === "about" && <p>Website developed by <a style={{textDecoration: 'underline'}} href="https://vaihe.com" target="_blank" rel="noopener noreferrer">Vaihe</a></p>}
                    {slug === "about" && <p><a href="/sitemap/" target="_blank" rel="noopener noreferrer">Sitemap</a></p>}
                </div>
            </div>

        </footer>
    )
}

export default Footer