// Gatsby Requirements
import React from 'react'

// Style
import * as CSS from './SocialMedia.module.scss'


const SocialMedia = () => {
    return (
        <div className={CSS.section}>
            <a aria-label="Follow us on Twitter" href="https://twitter.com/workededu" rel="noopener noreferrer" target="_blank">
                <img src="/icons/twitter.svg" aria-hidden="true" alt="" width="40" height="40" loading="lazy" />
            </a>

            <a aria-label="Follow us on Instagram" href="https://www.instagram.com/workededu/" rel="noopener noreferrer" target="_blank">
                <img src="/icons/instagram.svg" aria-hidden="true" alt="" width="40" height="40" loading="lazy" />
            </a>

            <a aria-label="Follow us on Facebook" href="https://www.facebook.com/workEDedu" rel="noopener noreferrer" target="_blank">
                <img src="/icons/facebook.svg" aria-hidden="true" alt="" width="40" height="40" loading="lazy" />
            </a>

            <a aria-label="Follow us on LinkedIn" href="https://www.linkedin.com/company/workededu" rel="noopener noreferrer" target="_blank">
                <img src="/icons/linkedin.svg" aria-hidden="true" alt="" width="40" height="40" loading="lazy" />
            </a>
        </div>
    )
}

export default SocialMedia